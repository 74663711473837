import Axios from "axios";
import { ToastsStore} from 'react-toasts';

import { HOST } from "./constants";
class Rest {
  static get(url, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(HOST + "/api" + url, { data: body });
        resolve(response);
      } catch (error) {
        ToastsStore.error(error.response.data.message)
        reject(error);
      }
    });
  }
  static post(url, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(HOST + "/api" + url, body);
        resolve(response);
      } catch (error) {
        ToastsStore.error(error.response.data.message)
        reject(error);
      }
    });
  }
  static put(url, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(HOST + "/api" + url, body);
        resolve(response);
      } catch (error) {
        ToastsStore.error(error.response.data.message)
        reject(error);
      }
    });
  }
}
export default Rest;