import React, { useState, useEffect } from "react";
import { Card, Container, Form, Button, Divider } from "semantic-ui-react";
import Axios from "axios";
import { HOST } from "../../utils/constants";
import { useParams } from "react-router-dom";
import EditableInput, { EditableRadio } from "./EditableInput";
import { ToastsStore } from "react-toasts";
import Rest from "../../utils/Rest";
import { EditableRoomImage, EditableRoomBanner } from "./EditableRoomImage";

export const RoomPage = () => {
  const [room, setRoom] = useState(null);

  const { roomId } = useParams();

  useEffect(() => {
    try {
      async function getRoom() {
        const result = await Axios({
          method: "get",
          url: `${HOST}/api/room/${roomId}`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setRoom(result.data.payload);
      }
      getRoom();
    } catch (err) {
      console.log(err);
    }
  }, [roomId]);

  if (!room) return <h1>Loading...</h1>;

  return (
    <>
      <Container>
        <Card style={{ width: "100%" }}>
          <Card.Content>
            <EditableRoomBanner roomId={room._id} image={room.bannerImage} />
          </Card.Content>
          <Card.Content>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "0px 5px 0px 5px",
              }}
            >
              {room.images.map((img, i) => (
                <EditableRoomImage
                  key={i}
                  roomId={room._id}
                  index={i}
                  image={img}
                />
              ))}
            </div>
          </Card.Content>
          <Card.Content>
            <Form>
              <Form.Group
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <EditableInput
                  roomId={room._id}
                  label="Room Name"
                  type="text"
                  name="name"
                  value={room.name}
                  placeholder="Enter room name"
                  width={6}
                />
                <EditableInput
                  roomId={room._id}
                  name="capacity"
                  label="Max Room Capacity"
                  value={room.capacity}
                  width={3}
                  placeholder="0"
                  type="number"
                />
                <EditableInput
                  roomId={room._id}
                  name="count"
                  label="No.of Rooms"
                  value={room.count}
                  width={3}
                  placeholder="0"
                  type="number"
                />
                <EditableInput
                  roomId={room._id}
                  name="rating"
                  label="Room Rating"
                  placeholder="0"
                  value={room.rating}
                  width={3}
                  type="number"
                />
              </Form.Group>
              <Divider />
              <Form.Group>
                <EditableInput
                  roomId={room._id}
                  name="price"
                  label="Room Price"
                  placeholder="0000"
                  value={room.price}
                  width={4}
                  type="number"
                />
                <EditableInput
                  roomId={room._id}
                  name="extraAdultPrice"
                  label="Extra Adult Price"
                  placeholder="0000"
                  value={room.extraAdultPrice}
                  width={4}
                  type="number"
                />
                <EditableInput
                  roomId={room._id}
                  name="extraChildPrice"
                  label="Extra Child Price"
                  placeholder="0000"
                  value={room.extraChildPrice}
                  width={4}
                  type="number"
                />
                <EditableInput
                  roomId={room._id}
                  name="extraMattressForChildren"
                  label="Extra Mattress Price for Children"
                  placeholder="000"
                  value={room.extraMattressForChildren}
                  width={4}
                  type="number"
                />
              </Form.Group>
              <Form.Group
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <EditableInput
                  roomId={room._id}
                  name="offer"
                  label="Offer Value"
                  placeholder="000"
                  value={room.offer}
                  width={2}
                  type="number"
                />
                <Form.Field>
                  <label>Offer Type</label>
                  <Button.Group>
                    <Button
                      positive={room.offerType === "%"}
                      onClick={async () => {
                        await editRoom(roomId, "offerType", "%");
                        setRoom({ ...room, offerType: "%" });
                      }}
                    >
                      %
                    </Button>
                    <Button.Or />
                    <Button
                      positive={room.offerType === "cash"}
                      onClick={async () => {
                        await editRoom(roomId, "offerType", "cash");
                        setRoom({ ...room, offerType: "cash" });
                      }}
                    >
                      Cash
                    </Button>
                  </Button.Group>
                </Form.Field>
                <EditableInput
                  roomId={room._id}
                  name="offerDescription"
                  label="Offer Description"
                  placeholder="Enter offer description"
                  value={room.offerDescription}
                  width={8}
                  type="text"
                />
                <Form.Field>
                  <label>Offer Status</label>
                  <EditableRadio
                    roomId={room._id}
                    name="isOffer"
                    toggle
                    checked={room.isOffer}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Card.Content>
        </Card>
      </Container>
    </>
  );
};

const editRoom = async (roomId, field, value) => {
  try {
    const result = await Rest.put("/room", { roomId, field, value });
    ToastsStore.success(result.data.message);
  } catch (error) {
    console.log(error.response.data.message);
  }
};
