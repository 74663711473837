import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { ToastsContainer, ToastsStore } from "react-toasts";

export const LoginLayout = (props) => {
  useEffect(() => {
    localStorage.clear();
  });

  return (
    <React.Fragment>
      <ToastsContainer
        lightBackground
        className="myWhite"
        store={ToastsStore}
      />
      <Menu
        secondary
        fluid
        style={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#FFF",
        }}
      >
        <Menu.Item>
          <Link to="/">
            <img
              alt="logo"
              style={{
                height: 45,
                width: 45,
                border: "1px solid #9D2E2C",
                borderRadius: 22.5,
              }}
              src="/vennington-logo.jpeg"
            />
          </Link>
        </Menu.Item>
      </Menu>
      <div
        style={{
          minHeight: "100vh",
          padding: "80px 5px 50px 5px",
          backgroundColor: "#F6FBFF",
        }}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};
