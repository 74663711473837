/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Redirect } from "react-router-dom";

export default (Component) => {
  const Auth = (props) => {
    const token = localStorage.getItem("token");
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { children } = props;
    if (
      token === null ||
      token === "" ||
      token === undefined ||
      !userDetails ||
      userDetails?.email !== "admin@gmail.com" ||
      userDetails?.password !== "password"
    ) {
      return <Redirect to="/login" />;
    } else {
      return <Component>{children}</Component>;
    }
  };
  return Auth;
};
