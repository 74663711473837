import React, { useState } from "react";
import {
  Header,
  Table,
  Container,
  Form,
  Input,
  Button,
} from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { HOST } from "../../utils/constants";
import { RoomListItem } from "./RoomListItem";

export const CheckAvailabilityPage = () => {
  const [rooms, setRooms] = useState([]);

  const [date, setDate] = useState({ from: null, to: null });

  async function getRooms() {
    const result = await Axios({
      method: "post",
      url: `${HOST}/api/booking/availability`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        checkIn: moment(date.from, "YYYY-MM-DD").format("YYYY/MM/DD"),
        checkOut: moment(date.to, "YYYY-MM-DD").format("YYYY/MM/DD"),
      },
    });
    setRooms(result.data.payload);
  }

  if (!rooms) return <h1>Loading...</h1>;

  return (
    <>
      <Container>
        <div style={{ marginTop: 0 }}>
          <Header as="h2" style={{ color: "#c69727", display: "flex" }}>
            CHECK AVAILABLE ROOMS
          </Header>
          <Form onSubmit={() => getRooms()}>
            <Form.Group inline>
              <Form.Field>
                <Input
                  required
                  label="From"
                  type="date"
                  value={date.from}
                  onChange={(e) => setDate({ ...date, from: e.target.value })}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  required
                  label="To"
                  type="date"
                  value={date.to}
                  onChange={(e) => setDate({ ...date, to: e.target.value })}
                />
              </Form.Field>
              <Form.Field>
                <Button type="submit" icon="search" />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        {/*  */}

        {/*  */}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Room Categories</Table.HeaderCell>
              <Table.HeaderCell>Available Rooms</Table.HeaderCell>
              <Table.HeaderCell>Rooms</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rooms.map((room, i) => (
              <RoomListItem key={i} room={room} />
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>{`Total Category - ${rooms.length}`}</Table.HeaderCell>
              <Table.HeaderCell>{`Total Available Rooms - ${rooms.reduce(
                (initial, room) => room.count + initial,
                0
              )}`}</Table.HeaderCell>
              <Table.HeaderCell>{`Total Rooms - ${rooms.reduce(
                (initial, room) => room.count + initial,
                0
              )}`}</Table.HeaderCell>
              <Table.HeaderCell>{`Min/Max Price - ${Math.min(
                ...rooms.map((room) => room.price)
              )}/${Math.max(
                ...rooms.map((room) => room.price)
              )}`}</Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    </>
  );
};
