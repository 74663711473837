import React, { createRef, useState } from "react";
import { Icon, Image } from "semantic-ui-react";
import Rest from "../../utils/Rest";
import { ToastsStore } from "react-toasts";
import Axios from "axios";
import { UPLOAD_PRESET, CLOUDINARY_URL } from "../../utils/constants";

export const EditableRoomBanner = ({ roomId, image }) => {
  const ref = createRef();

  const [img, setImg] = useState(image);

  const [uploading, setUploading] = useState(false);

  async function uploadImage(file) {
    if (file === undefined || file === null) {
      return;
    }
    try {
      setUploading(true);
      const imgURL = await AxiosUpload(file);
      editImage("/room/bannerimageupdate", {
        roomId,
        url: imgURL,
      });
      setImg(imgURL);
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  }

  async function removeImage(url) {
    if (img === url) {
      return;
    }
    try {
      setUploading(true);
      editImage("/room/bannerimageupdate", {
        roomId,
        url,
      });
      setImg(url);
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        maxHeight: 200,
        margin: "0px 5px 5px 0",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 10,
          width: 20,
          height: 20,
          backgroundColor: "white",
          border: "1px solid #949596",
          borderRadius: 10,
        }}
      >
        <Icon
          disabled={uploading}
          className="iconHover"
          name="delete"
          style={{ marginLeft: 1 }}
          onClick={() =>
            removeImage(
              "https://res.cloudinary.com/relive-cloud/image/upload/v1591460871/bannerDummyImage.png"
            )
          }
        />
      </div>
      <div
        style={{
          backgroundImage: `url(${img})`,
          width: "100%",
          height: 350,
          backgroundPosition: "center" /* Center the image */,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        onClick={() => ref.current.click()}
      >
        {/* <Image
          src={image}
          style={{ borderRadius: 25, width: "100%", height: 350 }}
          onClick={() => console.log("clicked image")}
        /> */}
      </div>
      <input
        disabled={uploading}
        hidden
        type="file"
        accept="image/png, .jpeg, .jpg, image/gif"
        ref={ref}
        onChange={(e) => uploadImage(e.target.files[0])}
      />
    </div>
  );
};

export const EditableRoomImage = ({ roomId, index, image }) => {
  const ref = createRef();

  const [img, setImg] = useState(image);

  const [uploading, setUploading] = useState(false);

  async function uploadImage(file, index) {
    if (file === undefined || file === null) {
      return;
    }
    try {
      setUploading(true);
      const imgURL = await AxiosUpload(file);
      editImage("/room/imageupdate", {
        roomId,
        url: imgURL,
        index,
      });
      setImg(imgURL);
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  }

  async function removeImage(url, index) {
    if (img === url) {
      return;
    }
    try {
      setUploading(true);
      editImage("/room/imageupdate", {
        roomId,
        url,
        index,
      });
      setImg(url);
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  }

  return (
    <div
      style={{
        position: "relative",
        width: 150,
        height: "100%",
        maxHeight: 200,
        margin: "130px 5px 5px 0",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 10,
          width: 20,
          height: 20,
          backgroundColor: "white",
          border: "1px solid #949596",
          borderRadius: 10,
        }}
      >
        <Icon
          disabled={uploading}
          name="delete"
          className="iconHover"
          style={{ marginLeft: 1 }}
          onClick={() =>
            removeImage(
              "https://res.cloudinary.com/relive-cloud/image/upload/v1591460871/bannerDummyImage.png",
              index
            )
          }
        />
      </div>
      <div>
        <Image
          disabled={uploading}
          src={img}
          style={{ borderRadius: 25, width: 150, height: 200 }}
          onClick={() => ref.current.click()}
        />
        <input
          disabled={uploading}
          hidden
          type="file"
          accept="image/png, .jpeg, .jpg, image/gif"
          ref={ref}
          onChange={(e) => uploadImage(e.target.files[0], index)}
        />
      </div>
    </div>
  );
};

const editImage = async (url, data) => {
  try {
    const result = await Rest.put(url, data);
    ToastsStore.success(result.data.message);
  } catch (error) {
    console.log(error.response.data.message);
  }
};

const AxiosUpload = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", UPLOAD_PRESET);
      const res = await Axios.post(CLOUDINARY_URL, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
      resolve(res.data.url);
    } catch (error) {
      reject(error);
    }
  });
};
