import React from "react";
import { Link } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";

export const RoomListItem = ({ room }) => (
  <Table.Row>
    <Table.Cell>
      {room.name}{" "}
      <Link to={`/room/${room._id}`}>
        <Icon name="edit outline" />
      </Link>
    </Table.Cell>
    <Table.Cell>{`${room.count}`}</Table.Cell>
    <Table.Cell>{`${room.price} Rs.`}</Table.Cell>
  </Table.Row>
);
