import React, { useState, useEffect } from "react";
import {
  Icon,
  Header,
  Table,
  Container,
  Modal,
  Button,
  Image,
  Form,
  Message,
} from "semantic-ui-react";
import Axios from "axios";
import { HOST } from "../../utils/constants";
import { RoomListItem } from "./RoomListItem";

export const RoomsPage = () => {
  const [rooms, setRooms] = useState(null);
  const [open, setOpen] = useState(false);

  async function getRooms() {
    const result = await Axios({
      method: "get",
      url: `${HOST}/api/room`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    setRooms(result.data.payload);
  }

  useEffect(() => {
    try {
      getRooms();
    } catch (err) {
      console.log(err);
    }
  }, []);

  if (!rooms) return <h1>Loading...</h1>;

  return (
    <>
      <Container>
        {/* <Segment clearing> */}
        <div>
          <Header as="h2" floated="left" style={{ color: "#9D2F2C" }}>
            Room Categories
          </Header>
          <Header as="h3" floated="right">
            <Icon
              className="iconHover"
              onClick={() => setOpen(true)}
              name="plus"
            />
          </Header>
        </div>
        {/* </Segment> */}
      </Container>
      <Container>
        {/*  */}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Room Categories{" - "}
                <Icon
                  name="refresh"
                  className="iconHover"
                  onClick={() => {
                    getRooms();
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Rooms</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rooms.map((room, i) => (
              <RoomListItem key={i} room={room} />
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>{`Total Category - ${rooms.length}`}</Table.HeaderCell>
              <Table.HeaderCell>{`Total Rooms - ${rooms.reduce(
                (initial, room) => room.count + initial,
                0
              )}`}</Table.HeaderCell>
              <Table.HeaderCell>{`Min/Max Price - ${Math.min(
                ...rooms.map((room) => room.price)
              )}/${Math.max(
                ...rooms.map((room) => room.price)
              )}`}</Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        {/*  */}
        <RoomAddModal open={open} setOpen={setOpen} />
      </Container>
    </>
  );
};

const RoomAddModal = ({ open, setOpen }) => {
  const initialState = {
    name: "",
    count: NaN,
    price: NaN,
  };

  const [roomData, setRoomData] = useState(initialState);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState("");

  const addRoom = async () => {
    setSuccess(null);
    setMessage("");
    const { name, count, price } = roomData;
    if (!name) {
      setError("name");
      return;
    }
    if (!count) {
      setError("count");
      return;
    }
    if (!price) {
      setError("price");
      return;
    }
    console.log("clicked");
    setError("");
    try {
      setSubmitting(true);
      const result = await Axios({
        method: "post",
        url: `${HOST}/api/room`,
        headers: {
          "Content-Type": "application/json",
        },
        data: roomData,
      });
      //
      console.log(result);
      setSuccess(true);
      setMessage("New Room Successfully Added");
      setRoomData(initialState);
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setMessage(err.message);
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} centered={false}>
      <Modal.Header>Add Room</Modal.Header>
      <Modal.Content image>
        <Image
          wrapped
          size="medium"
          src="https://images.oyoroomscdn.com/uploads/hotel_image/81612/f0af88ee7aca453a.jpg"
        />
        <Modal.Description>
          <Header>Provide Details to Add Room Category</Header>
          <p>
            We've found the following gravatar image associated with your e-mail
            address.
          </p>
          <Form>
            <Form.Field error={error === "name"}>
              <label>Room Category Name</label>
              <input
                value={roomData.name}
                onChange={(e) =>
                  setRoomData({ ...roomData, name: e.target.value })
                }
                placeholder="Room Category Name"
              />
            </Form.Field>
            <Form.Field error={error === "count"}>
              <label>No. of Rooms</label>
              <input
                value={roomData.count}
                onChange={(e) =>
                  setRoomData({ ...roomData, count: e.target.value })
                }
                type="number"
                placeholder="0"
              />
            </Form.Field>
            <Form.Field error={error === "price"}>
              <label>Price</label>
              <input
                value={roomData.price}
                onChange={(e) =>
                  setRoomData({ ...roomData, price: e.target.value })
                }
                type="number"
                placeholder="0000"
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {success !== null ? (
          <Message
            success={success === true}
            negative={success === false}
            content={message}
          />
        ) : null}
        <Button
          floated="left"
          negative
          onClick={() => setOpen(false)}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          primary
          onClick={() => addRoom()}
          loading={submitting}
          disabled={submitting}
        >
          Add New Room <Icon name="right chevron" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
