import React, { useState } from "react";
import moment from "moment";
import { Button, Table } from "semantic-ui-react";
import Rest from "../../utils/Rest";
import { ToastsStore } from "react-toasts";

const BookingListItem = ({ booking }) => {
  const [state, setState] = useState(booking.status);
  const handleBookingStatus = async (status) => {
    try {
      const result = await Rest.put("/booking", {
        bookingId: booking._id,
        field: "status",
        value: status,
      });
      setState(status);
      ToastsStore.success(result.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Table.Row positive={state === "stay"} error={state === "leave"}>
        <Table.Cell>{booking.name}</Table.Cell>
        <Table.Cell>{booking.mobile}</Table.Cell>
        <Table.Cell>
          {moment(booking.checkIn, "YYYY-MM-DD").format("DD MMM, YYYY")}
        </Table.Cell>
        <Table.Cell>
          {moment(booking.checkOut, "YYYY-MM-DD").format("DD MMM, YYYY")}
        </Table.Cell>
        <Table.Cell>{booking.room.name}</Table.Cell>
        <Table.Cell>{booking.originalPrice} Rs.</Table.Cell>
        <Table.Cell>{`${booking.discountPrice} Rs. (Offer Applied: ${
          booking.offer
        } ${booking.offerType === "cash" ? "Rs." : "%"})`}</Table.Cell>
        <Table.Cell>
          {`${booking.price} Rs. (Booking Id: ${booking.bookingId})`}
          <br />
          {`(Payment Id: ${booking.razorPayId})`}
        </Table.Cell>
        <Table.Cell>
          <Button.Group>
            <Button
              // disabled={state === "leave"}
              primary={state === "book"}
              onClick={() => handleBookingStatus("book")}
            >
              Booked
            </Button>
            <Button.Or />
            <Button
              // disabled={state === "leave"}
              positive={state === "stay"}
              onClick={() => handleBookingStatus("stay")}
            >
              Stay
            </Button>
            <Button.Or />
            <Button
              negative={state === "leave"}
              onClick={() => handleBookingStatus("leave")}
            >
              Leave
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default BookingListItem;
