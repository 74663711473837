import React from "react";
import { Table } from "semantic-ui-react";

export const RoomListItem = ({ room }) => {
  return (
    <Table.Row
      error={room.availability === 0}
      warning={
        room.availability <= (room.count * 50) / 100 && room.availability >= 1
      }
      positive={room.availability === room.count}
    >
      <Table.Cell>{room.name}</Table.Cell>
      <Table.Cell>{`${room.availability}`}</Table.Cell>
      <Table.Cell>{`${room.count}`}</Table.Cell>
      <Table.Cell>{`${room.price} Rs.`}</Table.Cell>
    </Table.Row>
  );
};
