import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { ToastsContainer, ToastsStore } from "react-toasts";

export const DefaultLayout = (props) => {
  let location = useLocation();
  const { pathname } = location;
  return (
    <React.Fragment>
      <ToastsContainer
        lightBackground
        className="myWhite"
        store={ToastsStore}
      />
      <Menu
        secondary
        fluid
        style={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#FFF",
        }}
      >
        <Menu.Item>
          <Link to="/">
            <img
              alt="logo"
              style={{
                height: 45,
                width: 45,
                border: "1px solid #9D2E2C",
                borderRadius: 22.5,
              }}
              src="/vennington-logo.jpeg"
            />
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item
            style={
              pathname === "/"
                ? { color: "#1478C2", backgroundColor: "#FFFFFF" }
                : null
            }
            as={Link}
            to="/"
            name="Bookings"
            active={pathname === "/"}
          />
          <Menu.Item
            style={
              pathname === "/check"
                ? { color: "#1478C2", backgroundColor: "#FFFFFF" }
                : null
            }
            as={Link}
            to="/check"
            name="Check Availability"
            active={pathname === "/check"}
          />
          <Menu.Item
            style={
              pathname === "/rooms"
                ? { color: "#1478C2", backgroundColor: "#FFFFFF" }
                : null
            }
            as={Link}
            to="/rooms"
            name="Rooms"
            active={pathname === "/rooms"}
          />
          <Menu.Item>|</Menu.Item>
          <Menu.Item
            style={
              pathname === "/logout"
                ? { color: "#1478C2", backgroundColor: "#FFFFFF" }
                : null
            }
            as={Link}
            to="/logout"
            name="Logout"
            active={pathname === "/logout"}
          />
        </Menu.Menu>
      </Menu>
      <div
        style={{
          minHeight: "100vh",
          padding: "80px 5px 50px 5px",
          backgroundColor: "#F6FBFF",
        }}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};
