import React, { useState } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { ToastsStore } from "react-toasts";

export const LoginPage = () => {
  let history = useHistory();

  const [data, setData] = useState({ email: "", password: "" });

  const [signing, setSigning] = useState(false);

  function login() {
    try {
      console.log(`${data.email} - ${data.password}`);
      setSigning(true);
      setTimeout(async () => {
        await localStorage.setItem(
          "token",
          Math.floor(Math.random() * 100000000000)
        );
        await localStorage.setItem("userDetails", JSON.stringify(data));
        history.push("/");
      }, 1000);
    } catch (err) {
      console.log(err);
      setData({ ...data, password: "" });
      setSigning(false);
      ToastsStore.error(err.message);
    }
  }

  return (
    <Grid textAlign="center" style={{ height: "88vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" style={{ color: "#9D2E2C" }} textAlign="center">
          <img
            alt="logo"
            as={Link}
            to="/"
            style={{
              height: 45,
              width: 45,
              border: "1px solid #9D2E2C",
              borderRadius: 22.5,
            }}
            src="/vennington-logo.jpeg"
          />
          Log-in to your account
        </Header>
        <Form size="large" onSubmit={() => login()}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              disabled={signing}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              disabled={signing}
            />
            <Button
              style={{ color: "#FFFFFF", backgroundColor: "#9D2E2C" }}
              fluid
              size="large"
              type="submit"
              disabled={signing}
              loading={signing}
            >
              Login
            </Button>
          </Segment>
        </Form>
        {/* <Message>
        New to us? <Link href="#">Sign Up</a>
      </Message> */}
      </Grid.Column>
    </Grid>
  );
};
