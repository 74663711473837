import React, { useEffect, useState } from "react";
import { Header, Table, Form, Input, Button } from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { HOST } from "../../utils/constants";
import BookingListItem from "./BookingListItem";
import { useHistory } from "react-router-dom";
import { RoomsLabel } from "./RoomsLabel";

export const BookingsPage = () => {
  let history = useHistory();

  let { search } = history.location || "";

  const [bookings, setBookings] = useState(null);

  const [date, setDate] = useState({
    checkIn: null,
    checkOut: null,
    roomId: null,
  });

  useEffect(() => {
    try {
      async function getBookings() {
        const result = await Axios({
          method: "get",
          url: `${HOST}/api/booking${search} `,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setBookings(result.data.payload);
      }
      getBookings();
    } catch (err) {
      console.log(err);
    }
  }, [search]);

  async function handleFilter() {
    let filter = "";
    if (date.checkIn && date.checkOut) {
      filter =
        filter +
        `checkIn=${moment(date.checkIn, "YYYY-MM-DD").format(
          "YYYY/MM/DD"
        )}&checkOut=${moment(date.checkOut, "YYYY-MM-DD").format(
          "YYYY/MM/DD"
        )}&`;
    }
    if (date.roomId) {
      filter = filter + `roomId=${date.roomId}&`;
    }
    history.push(`?${filter}`);
  }

  if (!bookings) return <h1>Loading...</h1>;

  return (
    <>
      <div style={{ margin: "0px 3%" }}>
        <div style={{ marginTop: 0 }}>
          <Header as="h2" style={{ color: "#c69727", display: "flex" }}>
            CHECK AVAILABLE ROOMS
          </Header>
          <Form onSubmit={() => handleFilter()}>
            <Form.Group inline>
              <Form.Field>
                <Input
                  required
                  label="From"
                  type="date"
                  value={date.from}
                  onChange={(e) =>
                    setDate({ ...date, checkIn: e.target.value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Input
                  required
                  label="To"
                  type="date"
                  value={date.to}
                  onChange={(e) =>
                    setDate({ ...date, checkOut: e.target.value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <Button type="submit" icon="search" />
              </Form.Field>
              <Form.Field>
                <Button
                  type="button"
                  content="Clear"
                  onClick={() => history.push("/")}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        {/*  */}

        <RoomsLabel date={date} setDate={setDate} />

        {/*  */}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Mobile</Table.HeaderCell>

              <Table.HeaderCell>Check-In Date</Table.HeaderCell>
              <Table.HeaderCell>Check-Out Date</Table.HeaderCell>

              <Table.HeaderCell>Room Category</Table.HeaderCell>
              <Table.HeaderCell>Total Amount </Table.HeaderCell>
              <Table.HeaderCell>Offer Amount </Table.HeaderCell>
              <Table.HeaderCell>Paid Amount</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {bookings.map((booking, i) => (
              <BookingListItem key={i} booking={booking} />
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};
