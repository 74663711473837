import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import "./App.css";

import DefaultLayout from "./layout/DefaultLayout";
import LoginLayout from "./layout/LoginLayout";

import BookingsPage from "./pages/BookingsPage";

import RoomsPage from "./pages/RoomsPage";
import RoomPage from "./pages/RoomPage";

import LoginPage from "./pages/LoginPage";
import CheckAvailabilityPage from "./pages/CheckAvailabilityPage";

const Login = () => (
  <LoginLayout>
    <LoginPage />
  </LoginLayout>
);

const Logout = (props) => {
  localStorage.clear();
  return <Redirect to="/login" />;
};

const NoMatch = () => {
  const location = useLocation();
  return (
    <DefaultLayout>
      <div>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    </DefaultLayout>
  );
};

// -----------------------------------------------------------------------------

const Bookings = () => (
  <DefaultLayout>
    <BookingsPage />
  </DefaultLayout>
);

const Rooms = () => (
  <DefaultLayout>
    <RoomsPage />
  </DefaultLayout>
);

const Room = () => (
  <DefaultLayout>
    <RoomPage />
  </DefaultLayout>
);

const CheckAvailability = () => (
  <DefaultLayout>
    <CheckAvailabilityPage />
  </DefaultLayout>
);

// --------------------------------------------------------------------

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Bookings} />
          <Route exact path="/rooms" component={Rooms} />
          <Route exact path="/room/:roomId" component={Room} />
          <Route exact path="/check" component={CheckAvailability} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route path="*" component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
