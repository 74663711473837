import React, { useState } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import { ToastsStore } from "react-toasts";
import Rest from "../../utils/Rest";

const EditableInput = ({
  roomId,
  label,
  type,
  name,
  value,
  placeholder,
  width,
}) => {
  const [state, setState] = useState(value);
  return (
    <Form.Field width={width}>
      <label
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {label}
      </label>
      <Form.Input
        type={type}
        name={name}
        value={state}
        placeholder={placeholder}
        onChange={(e) => setState(e.target.value)}
        onBlur={(e) => {
          const field = e.target.name;
          const value = e.target.value;
          const type = e.target.type;
          editRoom(roomId, field, type === "number" ? parseInt(value) : value);
        }}
      />
    </Form.Field>
  );
};

export const EditableRadio = ({ roomId, name, toggle, checked }) => {
  const [state, setState] = useState(checked);
  return (
    <Checkbox
      name={name}
      toggle={toggle}
      checked={state}
      onChange={(e, data) => {
        setState(data.checked);
        editRoom(roomId, data.name, data.checked);
      }}
    />
  );
};

export default EditableInput;

const editRoom = async (roomId, field, value) => {
  try {
    const result = await Rest.put("/room", { roomId, field, value });
    ToastsStore.success(result.data.message);
  } catch (error) {
    console.log(error.response.data.message);
  }
};
