import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import { HOST } from "../../utils/constants";
import Axios from "axios";

export const RoomsLabel = ({ date, setDate }) => {
  const [rooms, setRooms] = useState(null);

  async function getRooms() {
    const result = await Axios({
      method: "get",
      url: `${HOST}/api/room`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    setRooms(result.data.payload);
  }

  useEffect(() => {
    try {
      getRooms();
    } catch (err) {
      console.log(err);
    }
  }, []);

  if (!rooms) return <h1>Loading...</h1>;

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <Label.Group>
        {rooms.map((room, i) => (
          <Label
            key={i}
            onClick={() =>
              date.roomId === room._id
                ? setDate({ ...date, roomId: "" })
                : setDate({ ...date, roomId: room._id })
            }
            color={date.roomId === room._id ? "blue" : ""}
          >
            {room.name}
          </Label>
        ))}
      </Label.Group>
    </div>
  );
};
